@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./fonts/styles.css");

html,
body {
  margin: 0;
  font-family: "Lato-Regular", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #121212;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.noscroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.text-vertical {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

.general {
  scrollbar-width: auto !important;
}

h1 {
  font-size: larger;
  font-weight: bold;
  margin: 3px 0;
}

h2 {
  font-size: large;
  font-weight: bold;
  margin: 2px 0;
}

h3 {
  font-size: medium;
  font-weight: bold;
  margin: 2px 0;
}

h4 {
  font-size: small;
  font-weight: bold;
  margin: 2px 0;
}

p {
  margin-bottom: 20px;
}
