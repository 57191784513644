@font-face {
  font-family: Lato-Black;
  src: url(./Lato-Black.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-BlackItalic;
  src: url(./Lato-BlackItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-Bold;
  src: url(./Lato-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-BoldItalic;
  src: url(./Lato-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-Hairline;
  src: url(./Lato-Hairline.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-HairlineItalic;
  src: url(./Lato-HairlineItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-Italic;
  src: url(./Lato-Italic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-Light;
  src: url(./Lato-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-LightItalic;
  src: url(./Lato-LightItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Lato-Regular;
  src: url(./Lato-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Black;
  src: url(./Chivo-Black.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-BlackItalic;
  src: url(./Chivo-BlackItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Bold;
  src: url(./Chivo-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-BoldItalic;
  src: url(./Chivo-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-ExtraBold;
  src: url(./Chivo-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-ExtraBoldItalic;
  src: url(./Chivo-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Thin;
  src: url(./Chivo-Thin.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-ThinItalic;
  src: url(./Chivo-ThinItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Italic;
  src: url(./Chivo-Italic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Light;
  src: url(./Chivo-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-LightItalic;
  src: url(./Chivo-LightItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Regular;
  src: url(./Chivo-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-Medium;
  src: url(./Chivo-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: Chivo-MediumItalic;
  src: url(./Chivo-MediumItalic.ttf);
  font-display: swap;
}
